import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@data/services/api/auth/auth.service';
import { Observable } from 'rxjs';
import { user_rol_C } from '@data/constants/auth/auth.constant';
import { SessionService } from '@data/services/common/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(
    private sessionService: SessionService,
    private router: Router
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      try {
        let id_rol =  this.sessionService.getSessionRol();
        if(user_rol_C.cerebro.includes(id_rol)){
          this.router.navigateByUrl('/private/cerebro/dashboard');
        } else if(user_rol_C.client.includes(id_rol)) {
          this.router.navigateByUrl('/private/client/dashboard');
        }
        return true;
      } catch (error) {
        return true;
      }
    
  }
}
