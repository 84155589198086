<h1 mat-dialog-title class="dialog-title">{{ data.message }}</h1>
<div mat-dialog-content class="dialog-content">
  <p class="dialog-message">¡La presentación se ha generado exitosamente!</p>
  <p class="dialog-instructions">Para acceder a ella, puede optar por una de las siguientes opciones:</p>
  <ol class="dialog-list">
    <li class="dialog-list-item">
      Revise su bandeja de entrada de correo electrónico. Debería recibir un correo con un enlace a la presentación.
    </li>
    <li class="dialog-list-item">
      Haga clic en el botón de descarga a continuación para guardar la presentación en su dispositivo:
      <br>
      <button mat-raised-button color="primary" class="download-button" (click)="downloadPresentation()">
        <mat-icon>download</mat-icon> Descargar
      </button>
    </li>
    <li class="dialog-list-item">
      Haga clic en el siguiente enlace para abrir la presentación en una nueva ventana:
      <br>
      <a [href]="data.link" target="_blank" class="dialog-link">{{ data.link }}</a>
    </li>
  </ol>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="dialogRef.close()" class="close-button">Cerrar</button>
</div>
