import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {
    private dataStore: { [key: string]: any } = {};
    private captureRequest = new Subject<void>();
    captureRequest$ = this.captureRequest.asObservable();
    private captureRequestUpdateData = new Subject<void>();
    captureRequestUpdateData$ = this.captureRequestUpdateData.asObservable();

    constructor() { }

    setData(key: string, data: any) {
        this.dataStore[key] = data;
    }

    getData(key: string) {
        return this.dataStore[key];
    }

    clearData(key: string) {
        delete this.dataStore[key];
    }

    requestCapture() {
        this.captureRequest.next();
    }

    requestCaptureUpdateDate() {
        this.captureRequestUpdateData.next();
    }
}
