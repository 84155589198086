import { Component, Input, Injector, OnInit } from '@angular/core';
import { SharedDataService } from '@data/services/shared-data/shared-data.service';

@Component({
  selector: 'app-dynamic-button-wrapper',
  templateUrl: './dynamic-button-wrapper.component.html',
})
export class DynamicButtonWrapperComponent implements OnInit {
  @Input() component: any;
  @Input() data: any;
  @Input() options: any;
  private injector!: Injector; // Marcar como opcional

  constructor(
    private parentInjector: Injector,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.sharedDataService.setData('reportData', this.data);
    this.sharedDataService.setData('options', this.options);
    this.injector = Injector.create({
      providers: [
        {
          provide: 'reportData',
          useValue: this.sharedDataService.getData('reportData')
        },
        {
          provide: 'options',
          useValue: this.sharedDataService.getData('options')
        }
      ],
      parent: this.parentInjector
    });
    //console.log("Data in DynamicButtonWrapperComponent:", this.data); // Debugging
    //console.log("options in DynamicButtonWrapperComponent:", this.options); // Debugging
    //console.log("Component in DynamicButtonWrapperComponent:", this.component); // Debugging
  }

  createInjector(): Injector {
    return this.injector;
  }
}

