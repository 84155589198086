import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, of } from 'rxjs';
import { ExportExcelInterface } from '@data/iterfaces/exports/exportExcel.interface';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
    private http: HttpClient,
  ) { }

  private url: string = environment.apiConfig.v2.url_api;


  async exportData(data: any) {
    return new Promise(async (resolve, reject) => {
      try {
        try {
          const endPoint = `${this.url}test/excel`;
          this.http.post<ExportExcelInterface>(`${endPoint}`, data)
            .pipe(
              catchError(error => {
                return of(error);
              }))
            .subscribe(response => {
              return resolve(response);
            });
        } catch (error) {
          reject(new Error('Ha ocurrido un error en la petición.'));
        }
      } catch (error) {
        reject(new Error('Ha ocurrido un error al crear la promesa.'));
      }
    });
  }
}
