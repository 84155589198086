<div>
    <div class="d-flex justify-content-center">
        <app-export-buttons [buttonConfigs]="buttonConfigs"></app-export-buttons>
    </div>

    <div class="row mt-4 d-flex justify-content-center">
        <div class="col-lg">
            <div class="row justify-content-center">
                <ng-container *ngFor="let metric of selectedMetrics">
                    <div class="col-md-6 col-lg-4 mb-4">
                        <div class="chart-container negative-margin">
                            <div class="text-center">
                                <h5 class="title-chart">Actividad por plataforma ({{ metric }})</h5>
                                <app-dynamic-chart [data]="data[metric]"></app-dynamic-chart>
                            </div>
                            <div class="text-center mt-2 row d-flex justify-content-center">
                                <mat-card class="scorebrand-card">
                                    <!--  <mat-card-subtitle style="color: #fff;">
                                        {{data[metric].property}}
                                    </mat-card-subtitle> -->
                                    <mat-card-title>
                                        {{data[metric].total}}
                                    </mat-card-title>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>