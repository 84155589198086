import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GoogleSlidesInterface, Data } from '@data/iterfaces/exports/googleSlides.interface';
import { UserPermissionFileInterface, Row as User, Column } from '@data/iterfaces/user/user-permissions-file';
import { GoogleSlidesService } from '@data/services/api/exports/google/google-slides.service';
import { UserService } from '@data/services/api/user/user.service';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';
import { IsSuccessDialogGoogleSlidesComponent } from '../is-success-dialog-google-slides/is-success-dialog-google-slides.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { SharedDataService } from '@data/services/shared-data/shared-data.service';

@Component({
  selector: 'app-shared-dialog-google-slides',
  templateUrl: './shared-dialog-google-slides.component.html',
  styleUrls: ['./shared-dialog-google-slides.component.sass']
})
export class SharedDialogGoogleSlidesComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  reportForm: FormGroup;
  reportFormTwo: FormGroup;
  public steperOrientation: any = 'horizontal';
  public fileName: string = '';
  responseUserPerFile!: UserPermissionFileInterface;
  responseGoogleSlides!: GoogleSlidesInterface;
  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>([]);
  selectedUsers: User[] = [];
  users: User[] = [];
  filteredUsers: User[] = [];
  searchControl: FormControl = new FormControl();
  columns!: Column[];
  isChecked: boolean = false;
  isValidForm: boolean = false;
  body: object = {};
  loading!: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SharedDialogGoogleSlidesComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private googleSlidesService: GoogleSlidesService,
    private dialog: MatDialog,
    private sharedDataService: SharedDataService,

  ) {
    this.reportForm = this.formBuilder.group({
      fileName: ['', Validators.required],
    });
    this.reportFormTwo = this.formBuilder.group({
      users: [null, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.steperOrientation = window.innerWidth >= 700 ? 'horizontal' : 'vertical';
    await this.initializeData();
    this.searchControl.valueChanges.subscribe(value => this.filterUsers(value));
    this.reportForm.valueChanges.subscribe(value => this.isValidFirstForm());
  }

  async ngAfterViewInit(): Promise<void> {
    this.setPaginatorAndSort();
  }

  private setPaginatorAndSort() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  async initializeData() {
    await this.getUsersPermissions();
    this.filterUsers('');
  }

  private async getUsersPermissions() {
    this.responseUserPerFile = await this.userService.getUserPermissionsFile();
    if (!this.responseUserPerFile.ok) {
      this.snackbarService.openError('Error al obtener la informacion para generar el reporte.');
      this.dialogRef.close(this.responseUserPerFile);
    } else {
      this.users = this.responseUserPerFile.data.rows;
      this.columns = this.responseUserPerFile.data.columns;
      this.filteredUsers = [...this.users]; // Initial population of filteredUsers
      this.updateTable();
    }
  }

  async exportGoogleSlides() {
    this._generateDataRequest();
    const body: object = this._createRequestBody();
    //console.log(JSON.stringify(body, null, 2))
    this.loading = this.dialog.open(LoadingComponent, { maxWidth: "600px", disableClose: true });

    this.responseGoogleSlides = await this.googleSlidesService.googleSlides(body);
    this.dialogRef.close(this.responseGoogleSlides);
    this._closeDialog();
    if (!this.responseGoogleSlides?.ok) {
      return this.snackbarService.openError('Error al generar reporte.');
    }
    this._openDialogSuccess(this.responseGoogleSlides.data);
  }

  private _openDialogSuccess(data: Data): void {
    const dialogRef = this.dialog.open(IsSuccessDialogGoogleSlidesComponent, {
      width: '800px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data?.ok) {

      }
    });
  }

  private _generateDataRequest() {
    this.sharedDataService.requestCapture();
  }

  private _createRequestBody(): object {
    this.fileName = this.reportForm.value.fileName;
    const data = this.sharedDataService.getData('googleSlidesData');
    const clientId = this.sharedDataService.getData('clientId');

    this.body = {
      "typeOfFile": "pptx",
      "dataReport": data || null,
      "dataFileReport": {
        "clientId": clientId,
        "fileName": this.fileName,
        "sharingOptions": this.selectedUsers
      }
    };

    return this.body;
  }

  updateTable() {
    this.dataSource.data = this.selectedUsers;
    this.setPaginatorAndSort();
  }

  filterUsers(query: string) {
    const filterValue = query.toLowerCase();
    const filteredUsers = this.users.filter(user =>
      user.name.toLowerCase().includes(filterValue) || user.userEmail.toLowerCase().includes(filterValue)
    );
    const uniqueUsers = new Set([...filteredUsers, ...this.selectedUsers]);
    this.filteredUsers = Array.from(uniqueUsers);
  }

  removeUser(user: User) {
    this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser !== user);
    this.updateTable();
  }

  getColumns = (): string[] => {
    return this.columns?.map(column => column.value) || [];
  }

  isValidFirstForm() {
    this.isValidForm = this.reportForm.valid;
  }

  resetForm() {
    this.isChecked = false;
  }

  private _closeDialog() { this.loading.close() }

}
