<div>
    <div class="text-center">
        <div class="container">
            <div class="form-group row" data-html2canvas-ignore="true">
                <div class="col-lg-4">
                    <label>Tipo de gráfica:</label>
                    <div class="input-group">
                        <mat-form-field appearance="fill">
                            <mat-label>Graficas</mat-label>
                            <mat-select [(value)]="selected" required (selectionChange)="typeChart()">
                                <mat-option *ngFor="let chartType of chartTypes" [value]="chartType.type">
                                    {{ chartType.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-group" *ngIf="chartType === 'bar' && canAgrupado">
                        <mat-checkbox [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}" matInput
                            (change)="handleChange()">Agrupado
                        </mat-checkbox>
                    </div>
                    <div class="input-group" *ngIf="chartType === 'line'">
                        <mat-checkbox [(ngModel)]="isCheckedFill" [ngModelOptions]="{standalone: true}" matInput
                            (change)="handleChange()">Mostrar Área Bajo la Línea
                        </mat-checkbox>
                    </div>
                    <div class="input-group" *ngIf="chartType === 'line'">
                        <mat-checkbox [(ngModel)]="isCheckedStepped" [ngModelOptions]="{standalone: true}" matInput
                            (change)="handleChange()">Piso de Línea
                        </mat-checkbox>
                    </div>

                    <div class="input-group" *ngIf="chartType === 'line'">
                        <mat-form-field appearance="fill" class="input col-12">
                            <mat-label>Tensión</mat-label>
                            <input matInput type="number" [(ngModel)]="tensionValue" [min]="0" [minLength]="1"
                                [maxLength]="20" [max]="1" (input)="onInput($event)" (keydown)="onKeyDown($event)"
                                placeholder="0.4" inputmode="decimal" step="0.1">
                        </mat-form-field>
                    </div>

                    <button mat-raised-button (click)="downloadImg()">
                        <span>Exportar Imagen</span>
                    </button>
                </div>
            </div>
            <div class="w-100 justify-content-center" #html2canvas>
                <h5 class="pl-4 mt-5" class="subtitle-chart"> {{subtitle}}</h5>
                <div class="d-flex justify-content-center chart-container">
                    <canvas id="chartId-{{myChartId}}"></canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center mt-2 row d-flex justify-content-center">
        <mat-card class="scorebrand-card">
            <mat-card-subtitle style="color: #fff;">
                Total
            </mat-card-subtitle>
            <mat-card-title>
                {{total}}
            </mat-card-title>
        </mat-card>
    </div>
</div>