import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Graph } from '@data/iterfaces/dinamyc-charts.interface';
import { SharedDataService } from '@data/services/shared-data/shared-data.service';
import { Chart, ChartConfiguration, ChartType, registerables } from 'chart.js';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-dynamic-chart',
  templateUrl: './dynamic-chart.component.html',
  styleUrls: ['./dynamic-chart.component.sass']
})
export class DynamicChartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('html2canvas', { static: false }) html2canvas!: ElementRef;
  @Input() data!: Graph;
  myChartId = this.getRandomInt(100);
  chart!: Chart;
  chartType: ChartType = 'bar';
  chartTypes: { type: string; description: string }[] = [
    { type: 'bar', description: 'Gráfica de Barras' },
    { type: 'line', description: 'Gráfica de Líneas' },
    { type: 'pie', description: 'Gráfica de Sectores' },
    { type: 'doughnut', description: 'Gráfica de Donas' },
    { type: 'polarArea', description: 'Gráfica Polar' },
  ];
  selected: any = 'none';
  textTitle: string = '';
  subtitle: string = '';
  total: string = '';
  tensionValue: number = 0;
  isXandY: boolean = true;
  isStacked: boolean = true;
  isChecked: boolean = true;
  isCheckedFill: boolean = false;
  isCheckedStepped: boolean = false;
  canAgrupado: boolean = false;
  configurations: {
    label: string; value: string,
    chartType: string, visible: boolean
  }[] = [
      { label: 'Agrupado', value: 'agrupado', chartType: 'bar', visible: true },
      { label: 'Mostrar Área Bajo la Línea', value: 'mostrarArea', chartType: 'line', visible: true }
    ];
  originalColors: string[][] = [];
  viewInitialized = false;

  constructor(
    private sharedDataService: SharedDataService
  ) { Chart.register(...registerables); }

  ngOnInit(): void {
    //console.log(JSON.stringify(this.data, null, 2));
    //console.log("ngOnInit");
  }

  ngAfterViewInit(): void {
    this.initializeChart();
    this.viewInitialized = true;
    //console.log("ngAfterViewInit", this.viewInitialized);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(JSON.stringify(this.originalColors, null, 2))
    if (changes['data'] && changes['data'].currentValue) {
      //console.log("ngOnChanges", this.viewInitialized);
      this.updateChartProperties();
      if (this.viewInitialized) {
        this.initializeChart();
      }
    }
  }

  updateChartProperties() {
    this.subtitle = this.data.additionalInfo.subtitle;
    this.total = this.data.additionalInfo.total;
    this.chartType = this.data.additionalInfo.type;
    this.originalColors = this.data.dataChart.datasets.map((value: any) => value.backgroundColor);
  }

  initializeChart() {
    if (this.data) {
      this.textTitle = this.data.additionalInfo.textTitle;
      this.canAgrupado = this.data.dataChart.datasets.length > 1;
      this.createChart();
    }
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  createChart() {
    this.destroyChart();
    const config: ChartConfiguration = this.chartOptions();
    const canvas = this.html2canvas.nativeElement.querySelector('canvas');
    this.chart = new Chart(canvas.getContext('2d'), config);
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      return true;
    }
    return false;
  }

  chartOptions() {
    const chartsDisallow = ['pie', 'doughnut', 'polarArea'];
    this.handleColors(chartsDisallow);

    const config: ChartConfiguration = {
      type: this.chartType,
      data: this.data.dataChart,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
          intersect: false,
        },
        scales: this.configureScales(chartsDisallow),
        plugins: this.configurePlugins(chartsDisallow),
        elements: this.configureElements(),
      }
    };

    return config;
  }

  handleColors(chartsDisallow: string[]) {
    if (chartsDisallow.includes(this.chartType)) {
      let colors = this.assignRandomColors(this.data.dataChart.labels.length);

      this.data.dataChart.datasets = this.data.dataChart.datasets.map((value: any) => {
        const { backgroundColor, ...rest } = value;
        if (Array.isArray(backgroundColor) && backgroundColor.length > 0 && backgroundColor.length === this.data.dataChart.labels.length) {
          colors = backgroundColor;
        }

        return {
          ...rest,
          backgroundColor: colors
        };
      });
    } else if (this.originalColors.length) {
      this.data.dataChart.datasets = this.data.dataChart.datasets.map((value: any, index: number) => {
        const { backgroundColor, ...rest } = value;
        return {
          ...rest,
          backgroundColor: this.originalColors[index]
        };
      });
    }
  }

  configureScales(chartsDisallow: string[]) {
    const xAndYDisplay = !chartsDisallow.includes(this.chartType) && this.isXandY;
    return {
      x: {
        display: xAndYDisplay,
        grid: {
          borderColor: 'black'
        },
        stacked: this.isChecked,
      },
      y: {
        display: xAndYDisplay,
        min: 0,
        grid: {
          borderColor: 'black'
        },
        stacked: this.isChecked,
      },
    };
  }

  configurePlugins(chartsDisallow: string[]) {
    let tooltipCallbacks: any;
    if (!chartsDisallow.includes(this.chartType)) {
      tooltipCallbacks = {
        enabled: true,
        backgroundColor: '#000000',
        padding: 13,
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y;
            if (context.datasetIndex === 1) {
              const previous: any = context.chart.data.datasets[0].data[context.dataIndex];
              label += ' (diferencia: ' + (context.parsed.y - previous) + ')';
            }
            return label;
          }
        }
      };
    } else {
      tooltipCallbacks = {
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((acc: any, cur: any) => acc + cur, 0);
            const percentage = ((value / total) * 100).toFixed(3);
            return `${context.label}: ${value} (${percentage}%)`;
          }
        }
      };
    }

    return {
      title: {
        display: true,
        text: this.textTitle,
        font: {
          size: 18
        },
        color: '#000000'
      },
      legend: {
        display: true,
        labels: {
          usePointStyle: false,
          boxWidth: 26,
          boxHeight: 16
        }
      },
      tooltip: tooltipCallbacks
    };
  }

  configureElements() {
    return {
      point: {
        radius: 5,
        borderWidth: 4,
        hoverRadius: 8,
        hoverBorderWidth: 4,
        backgroundColor: 'blue'
      },
      line: {
        borderWidth: 3,
        fill: this.isCheckedFill,
        tension: this.tensionValue,
        stepped: this.isCheckedStepped,
      }
    };
  }

  typeChart() {
    this.chartType = this.selected;
    this.destroyChart()
    this.createChart();
    //console.log(this.chartType, JSON.stringify(this.data.additionalInfo.chartId, null, 2))
    //this.sharedDataService.setData('googleSlidesData', this.response?.data);
    this._generateDataRequest();
    const googleSlidesData = this.sharedDataService.getData('googleSlidesUpdateData');
    const updatedData = this.updateChartType(googleSlidesData,this.data.additionalInfo.chartId);
    //console.log(JSON.stringify(updatedData, null, 2))
  }

  private _generateDataRequest() {
    this.sharedDataService.requestCaptureUpdateDate();
  }

  updateChartType(data: any, chartId: string): any {
    Object.values(data.reportData).forEach((section: any) => {
      if (section.graphs) {
        Object.values(section.graphs).forEach((graph: any) => {
          if (graph.additionalInfo.chartId === chartId) {
            graph.additionalInfo.type = this.chartType;
          }
        });
      }
    });
    return data;
  }

  handleChange(configuracion?: any) {
    //console.log('Cambio en:', configuracion.label);
    this.createChart();
  }

  onInput(event: any) {
    const inputValue: number = parseFloat(event.target.value);
    if (!isNaN(inputValue)) {
      // Si el valor es válido, asignamos el nuevo valor
      this.tensionValue = inputValue;
      //console.log("Nuevo valor:", this.tensionValue);
      this.createChart();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    // Verifica si la tecla presionada es la "e"
    if (event.key === 'e') {
      // Evita la acción predeterminada (ingresar la "e")
      event.preventDefault();
      // Si se intenta ingresar la "e", no se realiza ninguna acción
      return;
    }
  }

  assignRandomColors(dataLength: any) {
    const colors = [];
    for (let i = 0; i < dataLength; i++) {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      colors.push(color);
    }
    return colors;
  }

  downloadImg() {
    const options = {
      backgroundColor: 'white',
      scale: 4,
      dpi: 300,
      letterRendering: true,
      useCORS: true
    };

    const data = this.html2canvas.nativeElement;
    html2canvas(data, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'imagen.png';
        link.target = '_blank';
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
