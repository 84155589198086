import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, of } from 'rxjs';
import { GoogleSlidesInterface } from '@data/iterfaces/exports/googleSlides.interface';

@Injectable({
  providedIn: 'root'
})
export class GoogleSlidesService {

  constructor(
    private http: HttpClient,
  ) { }

  private url: string = environment.apiConfig.v2.url_api;
  private baseUrl: string = `${this.url}files/reports/`;


  async googleSlides(body: object) {
    return new Promise<GoogleSlidesInterface>(async (resolve) => {
      try {
        const endPoint = `${this.baseUrl}google-slides`;
        this.http.post<GoogleSlidesInterface>(`${endPoint}`, body)
          .pipe(
            catchError(error => {
              return of(null);
            }))
          .subscribe(response => {
            return resolve(response as GoogleSlidesInterface);
          });
      } catch (error) {
        return resolve(error as GoogleSlidesInterface);
      }
    });
  }
}
