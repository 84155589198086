import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GoogleSlidesInterface } from '@data/iterfaces/exports/googleSlides.interface';
import { UserPermissionFileInterface } from '@data/iterfaces/user/user-permissions-file';
import { SharedDialogGoogleSlidesComponent } from './shared-dialog-google-slides/shared-dialog-google-slides.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from '@data/services/shared-data/shared-data.service';

@Component({
  selector: 'app-button-export-google-slides',
  templateUrl: './button-export-google-slides.component.html',
  styleUrls: ['./button-export-google-slides.component.sass']
})
export class ButtonExportGoogleSlidesComponent implements OnInit, OnChanges {
  @Input() reportData: any;
  responseGoogleSlides!: GoogleSlidesInterface;
  responseUserPerFile!: UserPermissionFileInterface;

  constructor(
    private dialog: MatDialog,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.updateReportData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('reportData' in changes) {
      this.updateReportData();
    }
  }

  private updateReportData(): void {
    this.reportData = this.sharedDataService.getData('reportData');
    //console.log("Updated reportData in ButtonExportGoogleSlidesComponent:", this.reportData);
  }

  openDialog(): void {
    if (this.reportData) {
      const dialogRef = this.dialog.open(SharedDialogGoogleSlidesComponent, {
        width: '760px',
        data: this.reportData,
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data?.ok) { }
      });
    } else {
      console.error("reportData is undefined. Dialog cannot be opened.");
    }
  }
}
