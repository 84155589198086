import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { SessionService } from '@data/services/common/session.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  closeDialog = new Subject<void>();
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.sessionService.isAuth();
    //console.log('AuthInterceptor')
    //console.log(token)
    if (!token) {
      this.dialog.closeAll();
      localStorage.clear();
      this.router.navigateByUrl('public/auth/login');
      this.snackbarService.openInfo('Sesión caducada por favor inicie nuevamente');
      //this.closeDialog.next();
      // Detenemos la solicitud HTTP devolviendo un observable vacío
      return throwError('Token inválido');
    }
    return next.handle(request);
  }
}
