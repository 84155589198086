import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SharedDataService } from '@data/services/shared-data/shared-data.service';
import { Html2canvasService } from '@shared/services/html2canvas/html2canvas.service';

@Component({
  selector: 'app-button-exports-html2canvas',
  templateUrl: './button-exports-html2canvas.component.html',
  styleUrls: ['./button-exports-html2canvas.component.sass']
})
export class ButtonExportsHtml2canvasComponent implements OnInit {
  @Input() reportData: any;
  @Input() options: any;

  constructor(
    private html2canvasService: Html2canvasService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.updateReportData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('reportData' in changes) {
      this.updateReportData();
    }
  }

  private updateReportData(): void {
    this.reportData = this.sharedDataService.getData('reportData');
    const optionsFromService = this.sharedDataService.getData('options');
    this.options = Object.keys(optionsFromService).filter(key => optionsFromService[key]);
  }

  download(option: string) {
    if (option === 'pdf') {
      this.html2canvasService.downloadPDF(this.reportData, 'reporte.pdf');
    } else if (option === 'img') {
      this.html2canvasService.downloadImg(this.reportData);
    }
  }
}
