import { Injectable } from '@angular/core';
import { LoginResponse } from '@data/iterfaces/auth/auth.inetrface';
import { CryptoService } from './crypto.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { navbar_menu } from '@data/constants/session/session.constant';
import { Router } from '@angular/router';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private cryptoService: CryptoService,
    private router: Router,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) { }

  setSessionData(data: LoginResponse) {
    localStorage.setItem("token", data.token);
    const sessionData = JSON.parse(this.cryptoService.decrypt(this.decodeToken().data));
    localStorage.setItem("client_logo_url", sessionData?.Client?.logo_url);
    localStorage.setItem("user_rol", sessionData.id_cat_rol);//sessionStorage
    localStorage.setItem("client_id", sessionData.id_client);
    //localStorage.setItem("user_rol_name", sessionData.Cat_rol.description);
  }

  getSessionData() {
    const sessionData = JSON.parse(this.cryptoService.decrypt(this.decodeToken().data));
    return sessionData;
  }

  getSessionRol() {
    let id_rol: any = localStorage.getItem("user_rol") ?? 0;
    return parseInt(id_rol);
  }

  getSessionClientId() {
    let client_id: any = localStorage.getItem("client_id") ?? 0;
    return parseInt(client_id);
  }

  decodeToken() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token') ?? '';
    return helper.decodeToken(token);
  }

  isExpiredToken() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token') ?? '';
    return helper.isTokenExpired(token);
  }

  getMaiNavbarItems() {
    type ObjectKey = keyof typeof navbar_menu;
    const key = this.getSessionRol() as ObjectKey;
    return navbar_menu[key].items;
  }

  isAuth(): boolean {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token');
    // Si no hay token en el almacenamiento local, permitir que el usuario inicie sesión
    if (!token) {
      return true;
    }
    // Si hay token, verificar si ha caducado
    return !helper.isTokenExpired(token); // Devuelve true si el token no ha caducado
  }

  isAuthComponents(): boolean {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('token');
    // Si no hay token en el almacenamiento local, permitir que el usuario inicie sesión
    if (!token) {
      return false;
    }
    const isValid: any = !helper.isTokenExpired(token)
    if (!isValid) {
      localStorage.clear();
      this.dialog.closeAll();
      this.router.navigateByUrl('public/auth/login');
      this.snackbarService.openInfo('Sesión caducada por favor inicie nuevamente');
      return false;
    }
    return true;
  }
}
