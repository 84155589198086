import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AES, enc } from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private key: string = environment.apiConfig.crypto_key;
  constructor() { }

  encrypt(msg: string): string {
    return AES.encrypt(msg, this.key).toString();
  }

  decrypt(code: string): string {
    let bytes = AES.decrypt(code, this.key);
    return bytes.toString(enc.Utf8);
  }
}
