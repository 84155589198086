import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from '@data/iterfaces/dinamyc-charts.interface';
import { ButtonConfigInterface } from '@data/iterfaces/exports/button-config.interface';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.sass']
})
export class DynamicTableComponent implements OnInit, AfterViewInit {
  @Input() data!: any;
  @Input() aditionals!: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public dataSource!: MatTableDataSource<any>;
  public columns!: Column[];
  public isLoading: Boolean = false;
  public reportData !: any;
  buttonConfigs: ButtonConfigInterface[] = [];
  transformed: any = {};

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.initializeData();
    }
  }

  ngAfterViewInit(): void {
    if (this.data) {
      this.initializeData();
    }
  }

  async initializeData() {
    if (this.data) {
      //console.log(this.data)
      // Iterar sobre las claves del objeto data
      for (const key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          // Desestructurar cada propiedad para extraer 'rows' y 'columns'
          const { rows, columns } = this.data[key];

          // Asignar 'rows' y 'columns' al objeto transformado
          this.transformed = { rows, columns };
        }
      }

      this.isLoading = true;
      this.columns = this.transformed.columns;
      this.setTableData(this.transformed.rows);
      this.reportData = {
        typeReport: "dynamicReport",
        data: this.transformed
      };
      this._initButtons();
      this.cdr.detectChanges(); // Forzar la detección de cambios
    }
  }

  private _initButtons() {
    this.buttonConfigs = [
      {
        type: "google",
        data: this.reportData,
      },
      {
        type: "excel",
        data: this.reportData,
        options: {
          excel: true,
          csv: true
        }
      }
    ];
    this.cdr.detectChanges(); // Forzar la detección de cambios
  }

  setTableData = (data: any): any => {
    if (!this.columns) {
      setTimeout(() => this.setTableData(data), 100);
    } else {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  getColumns = (): string[] => {
    if (!this.columns) {
      setTimeout(() => this.getColumns(), 100);
      return [];
    } else {
      return this.columns.map(column => column.value);
    }
  }
}
