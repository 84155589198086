import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'private',
    loadChildren: () => import('@modules/private/private.module').then(m => m.PrivateModule),
  }, 
  {
    path: '',
    loadChildren: () => import('@modules/public/public.module').then(m => m.PublicModule),
    canLoad: [AuthGuard]
  },
  { path: '**', redirectTo: 'client' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
