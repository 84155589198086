import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ButtonConfigInterface } from '@data/iterfaces/exports/button-config.interface';

@Component({
  selector: 'app-dev-test',
  templateUrl: './dev-test.component.html',
  styleUrls: ['./dev-test.component.sass']
})
export class DevTestComponent implements OnInit {
  public reportData!: any;
  @Input() data: any;
  public selectedMetrics: string[] = [];
  labels = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'];
  hombres = [103, 219, 271, 262, 172, 52];
  mujeres = [345, 626, 555, 782, 657, 223];
  buttonConfigs: ButtonConfigInterface[] = [];

  constructor() { }

  ngOnInit(): void {
    this.data = {
      "impresiones": {
        additionalInfo: {
          "textTitle": "Impresiones",
          "subtitle": "Impresiones",
          "total": 300,
          "type": "pie"
        },
        dataChart: {
          labels: this.labels,
          datasets: [{
            label: 'Hombres',
            backgroundColor: 'rgba(54, 162, 235, 0.5)', // Azul
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            data: this.hombres
          }, {
            label: 'Mujeres',
            backgroundColor: 'rgba(255, 168, 0, 0.5)', // Amarillo fuerte
            borderColor: 'rgba(255, 168, 0, 1)',
            borderWidth: 1,
            data: this.mujeres
          }]
        }
      },
      "clicks": {
        additionalInfo: {
          "textTitle": "Clicks",
          "subtitle": "Video Clicks",
          "total": 300,
          "type": "line"
        },
        dataChart: {
          labels: this.labels,
          datasets: [{
            label: 'Hombres',
            backgroundColor: 'rgba(54, 162, 235, 0.5)', // Azul
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            data: this.hombres
          }
          ]
        }
      },
    };

    this.initializeData();
  }

  ngOnChanges({ changes }: { changes: SimpleChanges; }) {
    //console.log('SimpleChanges additionals')
    //console.log(JSON.stringify(this.data, null, 2))
    this.initializeData();
  }

  initializeData() {
    if (this.data) {
      this.reportData = {
        typeReport: "dynamicReport",
        data: this.data
      };

      // Obtén las métricas disponibles
      this.selectedMetrics = Object.keys(this.data).filter(metric =>
        this.data[metric].total !== "0"
      );
      //console.log(JSON.stringify(this.selectedMetrics, null, 2))
    }

    this.buttonConfigs = [
      {
        type: "google",
        data: this.data,
      },
      {
        type: "excel",
        data: this.data,
        options: {
          excel: true,
          csv: false
        }
      },
      {
        type: "html2canvas",
        data: this.data,
        options: {
          pdf: true,
          img: true
        }
      },
    ]
  }

}
