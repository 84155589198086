<p class="dialog-message">¡Este reporte sera generado de manera general!</p>
<ng-template #generateButton>
    <button mat-raised-button color="primary" type="submit" (click)="exportGoogleSlides()">
        Generar Reporte
    </button>
</ng-template>

<mat-card>
    <mat-card-content>
        <mat-horizontal-stepper [orientation]="steperOrientation" [linear]="true" #stepper>
            <mat-step [stepControl]="reportForm">
                <ng-template matStepLabel>Presentación</ng-template>
                <form [formGroup]="reportForm">
                    <div class="form-group row d-flex justify-content-center mb-3">
                        <mat-form-field appearance="fill" class="input col-12">
                            <mat-label>Nombre de la presentación</mat-label>
                            <input matInput formControlName="fileName">
                            <mat-error *ngIf="reportForm.controls['fileName'].hasError('required')">
                                El nombre es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>

                <div class="input-group">
                    <mat-checkbox [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}" matInput>
                        Compartir presentación
                        <mat-hint>(Marque si desea compartir esta presentación)</mat-hint>
                    </mat-checkbox>

                </div>
                <div *ngIf="isValidForm && !isChecked" class="d-flex justify-content-center mb-3 mt-2">
                    <ng-container *ngTemplateOutlet="generateButton"></ng-container>
                </div>
                <div *ngIf="isChecked" class="d-flex justify-content-center">
                    <button mat-button matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step [stepControl]="reportFormTwo" *ngIf="isChecked">
                <ng-template matStepLabel>Compartir</ng-template>
                <form [formGroup]="reportFormTwo">
                    <div class="modal-scrollbar">

                        <div class="form-group row d-flex justify-content-center mb-3">
                            <p class="help-text">Seleccione los usuarios con quienes desea compartir la presentación</p>
                            <mat-form-field class="select-campaigns mt-2" appearance="fill">


                                <mat-label>Selección de usuarios</mat-label>

                                <mat-select multiple [(value)]="selectedUsers" formControlName="users"
                                    (openedChange)="filterUsers('')" (selectionChange)="updateTable()">
                                    <div class="search-box">
                                        <input matInput placeholder="Buscar..." [formControl]="searchControl"
                                            (click)="$event.stopPropagation()">
                                    </div>
                                    <mat-option *ngFor="let user of filteredUsers" [value]="user">
                                        {{user.name}} - {{user.userEmail}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="reportFormTwo.controls['users'].hasError('required')">
                                    El usuario es <strong>requerido</strong>.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div *ngIf="selectedUsers.length > 0" class="form-group row d-flex justify-content-center mb-3">
                            <p class="help-text">Seleccione los permisos de la presentación</p>

                            <table mat-table [dataSource]="dataSource" matSort>
                                <ng-container *ngFor="let column of columns" [matColumnDef]="column.value">
                                    <th mat-header-cell class="text-center text-header" *matHeaderCellDef>
                                        <div *ngIf="column.value === 'name'|| column.value === 'userEmail' "
                                            mat-sort-header>{{column.title}}</div>
                                        <div *ngIf="column.value !== 'name' && column.value !== 'userEmail' ">
                                            {{column.title}}</div>
                                    </th>
                                    <td mat-cell class="td-title" *matCellDef="let element">
                                        <ng-container sticky
                                            *ngIf="column.value === 'name' || column.value  === 'userEmail' ">
                                            <span>{{element[column.value]}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="column.value == 'permission'">
                                            <mat-form-field>
                                                <mat-select [(value)]="element.permission">
                                                    <mat-option
                                                        *ngFor="let permission of responseUserPerFile.data.permisions"
                                                        [value]="permission">
                                                        {{permission | uppercase}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                        <ng-container *ngIf="column.value == 'sharingFolder'">
                                            <mat-checkbox [(ngModel)]="element.sharingFolder"
                                                [ngModelOptions]="{standalone: true}">
                                            </mat-checkbox>
                                        </ng-container>
                                        <ng-container *ngIf="column.value == 'sharingPresentation'">
                                            <mat-checkbox [(ngModel)]="element.sharingPresentation"
                                                [ngModelOptions]="{standalone: true}">
                                            </mat-checkbox>
                                        </ng-container>
                                        <ng-container stickyEnd *ngIf="column.value == 'actions'">
                                            <button mat-icon-button color="warn" (click)="removeUser(element)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
                                <tr mat-row *matRowDef="let row; columns: getColumns();"></tr>
                            </table>
                            <mat-paginator #paginator [pageSizeOptions]="[5,10,25,50,100]" [pageSize]="5"
                                showFirstLastButtons aria-label="select items per page">
                            </mat-paginator>
                        </div>

                    </div>
                    <div class="d-flex justify-content-center">
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>

            <mat-step *ngIf="isChecked && isValidForm">
                <ng-template matStepLabel>Generar</ng-template>
                <div class="d-flex justify-content-center mb-3 mt-2">
                    <ng-container *ngTemplateOutlet="generateButton"></ng-container>
                </div>
                <div class=" d-flex justify-content-center">
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button (click)="resetForm(); stepper.reset(); ">Reset</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card-content>
</mat-card>