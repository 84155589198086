import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class Html2canvasService {

  constructor() { }

  downloadPDF(data: any, name?: string) {
    const options = { background: 'white', scale: 3 };
    html2canvas(data, options)
      .then((canvas) => {
        const img = canvas.toDataURL("image/jpeg", 1);
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [data.offsetHeight, data.offsetWidth]
        });
        pdf.addImage(img, 'JPEG', 30, 15, data.offsetWidth - 60, data.offsetHeight - 30);
        pdf.save(name);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  downloadPDFLandscape(data: any, name?: string) {
    const options = {
      backgroundColor: 'white',
      scale: 4,
      dpi: 300,
      letterRendering: true,
      useCORS: true
    };
    html2canvas(data, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const width = canvas.width;
        const height = canvas.height;

        const pdf = new jsPDF('landscape', 'px');

        const aspectRatio = width / height;
        const maxWidth = pdf.internal.pageSize.getWidth() - 20; // Ajustar margen izquierdo y derecho según necesidad
        const maxHeight = pdf.internal.pageSize.getHeight() - 20; // Ajustar margen superior e inferior según necesidad

        let finalWidth = width;
        let finalHeight = height;

        if (width > maxWidth || height > maxHeight) {
          if (aspectRatio > 1) {
            finalWidth = maxWidth;
            finalHeight = maxWidth / aspectRatio;
          } else {
            finalHeight = maxHeight;
            finalWidth = maxHeight * aspectRatio;
          }
        }

        const x = (pdf.internal.pageSize.getWidth() - finalWidth) / 2;
        const y = (pdf.internal.pageSize.getHeight() - finalHeight) / 2;
        pdf.addImage(imgData, 'PNG', x, y, finalWidth, finalHeight);

        /* const watermarkImgData = './../../../assets/images/cerebro/identity/circle-logo.PNG';

        const watermarkWidth = 90; // Ancho de la imagen de marca de agua en píxeles
        const watermarkHeight = 50; // Alto de la imagen de marca de agua en píxeles

        const watermarkX = pdf.internal.pageSize.getWidth() - watermarkWidth - 10; // Posición X de la esquina derecha inferior de la marca de agua
        const watermarkY = pdf.internal.pageSize.getHeight() - watermarkHeight - 10; // Posición Y de la esquina derecha inferior de la marca de agua

        pdf.addImage(watermarkImgData, 'PNG', watermarkX, watermarkY, watermarkWidth, watermarkHeight); */

        pdf.save(name);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  downloadImg(data: any) {
    const options = {
      backgroundColor: 'white',
      scale: 4,
      dpi: 300,
      letterRendering: true,
      useCORS: true
    };

    html2canvas(data, options)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'imagen.png';
        link.target = '_blank';
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
