<div class="container-table">
    <div class="row justify-content-between mb-4">
        <!-- Iteración sobre los valores de aditionals para los mat-cards -->
        <div class="col-lg-3 col-md-4 col-sm-6 mb-4" *ngFor="let value of aditionals">
            <mat-card class="w-100 mat-card-dinamyc">
                <mat-card-subtitle>{{ value.title }}</mat-card-subtitle>
                <mat-card-title>{{ value.total }}</mat-card-title>
            </mat-card>
        </div>
    </div>

    <div class="row justify-content-end mb-2">
        <!-- Componente de botones de exportación -->
        <div class="col-lg-3 col-md-4 col-sm-6">
            <app-export-buttons [buttonConfigs]="buttonConfigs"></app-export-buttons>
        </div>
    </div>

    <div class="row justify-content-end mb-2">
        <!-- Tabla mat-table -->
        <div class="container-table">
            <table mat-table [dataSource]="dataSource" class="deadlines-table hide-scrollbar" matSort>
                <!-- Definición dinámica de columnas -->
                <ng-container *ngFor="let column of columns" [matColumnDef]="column.value">
                    <th mat-header-cell class="text-center text-header" *matHeaderCellDef mat-sort-header>
                        {{ column.title }}
                    </th>
                    <td mat-cell class="td-title" *matCellDef="let element">
                        <ng-container>
                            {{ element[column.value] }}
                        </ng-container>
                    </td>
                </ng-container>
                <!-- Filas y columnas de encabezado -->
                <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getColumns();"></tr>
            </table>
        </div>
    </div>

    <div class="d-flex flex-row-reverse bd-highlight" *ngIf="isLoading">
        <!-- Sección de total al revés para flexibilidad -->
        <div class="p-2 bd-highlight pl-grand-total" *ngFor="let value of aditionals.slice().reverse()">
            {{value.total}}
        </div>
        <div class="p-2 bd-highlight">
            <strong>Grand Total</strong>
        </div>
    </div>

    <!-- Paginador para navegación -->
    <div class="row justify-content-end">
        <div class="col-lg-12">
            <mat-paginator #paginator [pageSizeOptions]="[5,10,25,50,100]" [pageSize]="5" showFirstLastButtons
                aria-label="">
            </mat-paginator>
        </div>
    </div>
</div>