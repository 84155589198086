import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultResponse } from '@data/iterfaces/default-response';
import { IgeneralApiResponse } from '@data/iterfaces/reports/dv360/reports/performance/api-request.interface';
import { UserPermissionFileInterface } from '@data/iterfaces/user/user-permissions-file';
import { environment } from 'environments/environment';
import { catchError, firstValueFrom, of, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url: string = environment.apiConfig.url_api;
  private urlV2: string = environment.apiConfig.v2.url_api;
  private baseUrl: string = `${this.urlV2}user`;
  private _refresh$ = new Subject<void>();

  get refresh$() {
    return this._refresh$;
  }

  constructor(private http: HttpClient) { }

  get_user_information(user_id: number) {
    const scope = `${this.url}user/getInformation`;
    return this.http.post<IgeneralApiResponse>(`${scope}`, { user_id });
  }

  update_user(user_info: any) {
    const scope = `${this.url}user/update`;
    return this.http.post<IgeneralApiResponse>(`${scope}`, user_info);
  }

  get_rols(client_id: number) {
    const scope = `${this.url}user/getRols/${client_id}`;
    return this.http.get<IgeneralApiResponse>(`${scope}`);
  }

  //Refactor
  update = async (body: object) => {
    return new Promise<DefaultResponse>(async (resolve) => {
      try {
        const endPoint = `${this.baseUrl}`;
        this.http.put<DefaultResponse>(`${endPoint}`, body)
          .pipe(
            catchError(error => {
              return of(null);
            }))
          .subscribe(response => {
            return resolve(response as DefaultResponse);
          });
      } catch (error) {
        return resolve(error as DefaultResponse);
      }
    });
  }

  deactivate = async (body: object) => {
    return new Promise<DefaultResponse>(async (resolve) => {
      try {
        const endPoint = `${this.baseUrl}/deactivate`;
        const response = await firstValueFrom(
          this.http.post<DefaultResponse>(`${endPoint}`, body).pipe(tap(() => this._refresh$.next()))
        );
        return resolve(response as DefaultResponse);
      } catch (error) {
        return resolve(error as DefaultResponse);
      }
    });
  }

  activate = async (body: object) => {
    return new Promise<DefaultResponse>(async (resolve) => {
      try {
        const endPoint = `${this.baseUrl}/restore`;
        const response = await firstValueFrom(
          this.http.post<DefaultResponse>(`${endPoint}`, body).pipe(tap(() => this._refresh$.next()))
        );
        return resolve(response as DefaultResponse);
      } catch (error) {
        return resolve(error as DefaultResponse);
      }
    });
  }

  destroy = async (id: number): Promise<boolean> => {
    try {
      const endPoint = `${this.baseUrl}/${id}`;
      const response = await this.http.delete(endPoint, { observe: 'response' }).toPromise();
      if (response?.status === 204) {
        this._refresh$.next();
      }
      return response?.status === 204 || false;
    } catch (error) {
      //console.error('Error al realizar la eliminación', error);
      return false; // La eliminación no fue exitosa debido a un error
    }
  }

  getUserPermissionsFile() {
    return new Promise<UserPermissionFileInterface>(async (resolve) => {
      try {
        const endPoint = `${this.baseUrl}/permissions-file`;
        this.http.get<UserPermissionFileInterface>(`${endPoint}`)
          .pipe(
            catchError(error => {
              return of(error as UserPermissionFileInterface);
            }))
          .subscribe(response => {
            return resolve(response as UserPermissionFileInterface);
          });
      } catch (error) {
        return resolve(error as UserPermissionFileInterface);
      }
    });
  }

  store(data: object) {
    const endPoint = `${this.baseUrl}`;
    return this.http.post<DefaultResponse>(`${endPoint}`, data);
  }
}
