<div class="d-flex justify-content-center">
    <div class="d-flex justify-content-end margin-bottom">
        <button mat-raised-button [matMenuTriggerFor]="menu" data-html2canvas-ignore="true">
            Exportar
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="individualMenu">
                Individual
            </button>
            <mat-menu #individualMenu="matMenu">
                <ng-container *ngFor="let button of buttonsIndividual">
                    <app-dynamic-button-wrapper [component]="button.component" [data]="button.data"
                        [options]="button.options">
                    </app-dynamic-button-wrapper>
                </ng-container>
            </mat-menu>
            <button *ngIf="buttonsGeneral.length" mat-menu-item [matMenuTriggerFor]="generalMenu">
                General
                <mat-menu #generalMenu="matMenu">
                    <ng-container *ngFor="let button of buttonsGeneral">
                        <app-dynamic-button-wrapper [component]="button.component" [data]="button.data"
                            [options]="button.options">
                        </app-dynamic-button-wrapper>
                    </ng-container>
                </mat-menu>
            </button>
        </mat-menu>
    </div>
</div>