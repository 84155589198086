import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DataExportInterface } from '@data/iterfaces/exports/dataExport.interface';
import { ExportExcelInterface } from '@data/iterfaces/exports/exportExcel.interface';
import { ExcelService } from '@data/services/api/exports/excel/excel.service';
import { SharedDataService } from '@data/services/shared-data/shared-data.service';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';

@Component({
  selector: 'app-button-export-excel',
  templateUrl: './button-export-excel.component.html',
  styleUrls: ['./button-export-excel.component.sass']
})
export class ButtonExportExcelComponent implements OnInit {
  @Input() reportData: any;
  @Input() options: any;
  url!: string;
  fileName!: string;
  response!: ExportExcelInterface;
  private data: DataExportInterface = {
    typeOfFile: '',
    dataReport: {}
  };

  constructor(
    private excelService: ExcelService,
    private snackbarService: SnackbarService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.updateReportData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('reportData' in changes) {
      //console.log(" ngOnChanges this.ButtonExportExcelComponent")
      this.updateReportData();
    }
  }

  private updateReportData(): void {
    this.reportData = this.sharedDataService.getData('reportData');
    const optionsFromService = this.sharedDataService.getData('options');
    this.options = Object.keys(optionsFromService).filter(key => optionsFromService[key]);
    //console.log("Updated reportData in ButtonExportExcelComponent:", this.reportData);
    //console.log("Updated options in ButtonExportExcelComponent:", this.options);
  }

  async download(option: string) {
    if (option === 'excel') {
      await this.exportData('excel');
    } else if (option === 'csv') {
      await this.exportData('csv');
    }
  }

  private async exportData(fileType: string): Promise<void> {
    this.data.typeOfFile = fileType;
    this.data.dataReport = this.reportData;
    this.response = await this.excelService.exportData(this.data) as ExportExcelInterface;
    this.url = this.response.url;
    this.fileName = this.response.fileName;
    await this.downloadFile();
  }

  private async downloadFile(): Promise<void> {
    const response = await fetch(this.url);
    if (!response?.ok) { return this.snackbarService.openError('Error al generar descarga del archivo.'); }
    const blob = await response.blob();
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = this.fileName;
    downloadLink.click();
  }
}
