import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ButtonExportGoogleSlidesComponent } from '../button-export-google-slides/button-export-google-slides.component';
import { ButtonExportExcelComponent } from '../button-export-excel/button-export-excel.component';
import { ButtonExportsHtml2canvasComponent } from '../button-exports-html2canvas/button-exports-html2canvas.component';
import { ButtonConfigInterface } from '@data/iterfaces/exports/button-config.interface';

@Component({
  selector: 'app-export-buttons',
  templateUrl: './export-buttons.component.html',
  styleUrls: ['./export-buttons.component.sass']
})
export class ExportButtonsComponent implements OnInit {
  @Input() buttonConfigs: ButtonConfigInterface[] = [];

  // Mapping of types to components
  private availableComponents: { [key: string]: any } = {
    google: ButtonExportGoogleSlidesComponent,
    excel: ButtonExportExcelComponent,
    html2canvas: ButtonExportsHtml2canvasComponent,
  };

  // Processed buttons to be rendered
  buttons: ButtonConfigInterface[] = [];
  buttonsIndividual: ButtonConfigInterface[] = [];
  buttonsGeneral: ButtonConfigInterface[] = [];


  ngOnInit(): void {
    this.processButtonConfigs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('buttonConfigs' in changes) {
      this.processButtonConfigs();
    }
  }

  private processButtonConfigs(): void {
    this.buttons = this.buttonConfigs
      .map(config => this.mapConfigToButton(config))
      .filter(button => button.component);

    this.buttonsIndividual = this.filterIndividualButtons(this.buttons);
    this.buttonsGeneral = this.filterGeneralButtons(this.buttons);
  }

  private mapConfigToButton(config: ButtonConfigInterface): ButtonConfigInterface {
    return {
      ...config,
      component: this.availableComponents[config.type],
    };
  }

  private filterIndividualButtons(buttons: ButtonConfigInterface[]): ButtonConfigInterface[] {
    return buttons.filter(button => button.type !== 'google');
  }

  private filterGeneralButtons(buttons: ButtonConfigInterface[]): ButtonConfigInterface[] {
    return buttons.filter(button => button.type === 'google');
  }

}
