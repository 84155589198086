export const environment = {
  production: false,
  apiConfig: {
    //url_api: 'http://localhost:3100/api/cerebro/v1/',
    url_api: 'https://api-campaigns-qa.respaldo.cerebro.media/api/cerebro/v1/',
    token_test: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiVTJGc2RHVmtYMS94c3hwczdKcVlERXc5YU4rVXZZVnlyVWUvUVJSendIUkdtN1pwc1FkdXdYd1RzSnpFb0pGQzhSTDhYd3pqa3ZNdmVRTmNkT1p5MDcyWEoyZUNveEpIWjN2WG9yN1IzMzE5WWlweWU4cU1lU3NrVzZvYk13MTNkV2EwdWJGYTRxbGUrSlo2d2w2cEFNZkgrcVAvTEtGRjhhWFR4VEIwWlhndXkvbnR4LzFSRFhWVklsMFFsc21iMllmNTkvL3J6ZFFTS2tmMUF0bzgwcUhBbmg3Rk53am45bXREeThqWldRQmsvSk1uc0lmNERDZ1BYVGEzYUJCZmh1VnlGMFd1OFJkWkF6ODF1QU5LUGxXTTdFUExzYmlxeHN6aENDM042enpuaEl4ODdUMVhlVGdWbGQxV1VSYllJSnVjUXR2NnZ2LzlYakJ6bkhNdk1zbXRUVi9yWEJpY1BjRXZpY1lPN0JJWWVoYjBMWGFCcFU2SlRXRVZyNXM4ZWlKMW8vMjVIR3hDMnVZQnhRU1dURTNPUm42RFNqeFk0ZlpPNTJwWUVsUT0iLCJpYXQiOjE2NjQzNTU5MzgsImV4cCI6MTY2NDM2NjczOH0.rSMi2xAAJZls0makqFE7W1nETZP96Nk-bpZup1qlj5o',
    api_key: 'NjU4OWVmODNlMGZmNWU2MmQ0ZTM3NjkyOTYyOGVmMjdmZWYxNGJiN2NlN2NkMzViYTNlYTc5Y2U2Y2E1NWVkNA==',
    crypto_key: "cerebro_campaigns_MzZmYzdjODdhZjdmNDJhMzYzM2Q3OTI1ZDY0NTg0NzVlN2ZiYzRlODgwMWQ5MTNmODZmZjc0MTRiZmQ2N2FkYWIwYTFmNGExY2E2YTQ0NjRiMDg5YTY2NGI1YjI1OWZiOGE3MjQ1YTFmM2RiZTA4YjkwYjc0ZWRiYjQzY2FlNDU=",
    v2: {
      //url_api: 'http://localhost:3100/api/cerebro/v2/',
      url_api: 'https://api-campaigns-qa.respaldo.cerebro.media/api/cerebro/v2/',
    }
  },
  firebaseConfig: {
    apiKey: "AIzaSyDlxduSIIKbv3Xi8IWFYkvhNgvlZIQXHYA",
    authDomain: "dv360-api-343616.firebaseapp.com",
    projectId: "dv360-api-343616",
    storageBucket: "dv360-api-343616.appspot.com",
    messagingSenderId: "874405779097",
    appId: "1:874405779097:web:200c85fadc5e90490d4a08",
    measurementId: "G-80RKJQR375"
  }
};
