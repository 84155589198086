import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as fromComponents from './components';
import { LoadingComponent } from './components/loading/loading.component';
import { AngularMaterialModule } from '@shared/angular-material/angular-material.module';
import { ButtonExportExcelComponent } from './exports/button-export-excel/button-export-excel.component';
import { DevTestComponent } from './dev/dev-test/dev-test.component';
import { DynamicChartComponent } from './chart/dynamic-chart/dynamic-chart.component';
import { ButtonExportGoogleSlidesComponent } from './exports/button-export-google-slides/button-export-google-slides.component';
import { SharedDialogGoogleSlidesComponent } from './exports/button-export-google-slides/shared-dialog-google-slides/shared-dialog-google-slides.component';
import { IsSuccessDialogGoogleSlidesComponent } from './exports/button-export-google-slides/is-success-dialog-google-slides/is-success-dialog-google-slides.component';
import { DynamicButtonWrapperComponent } from './exports/dynamic-button-wrapper/dynamic-button-wrapper.component';
import { ExportButtonsComponent } from './exports/export-buttons/export-buttons.component';
import { ButtonExportsHtml2canvasComponent } from './exports/button-exports-html2canvas/button-exports-html2canvas.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicLoadingComponent } from './components/dynamic-loading/dynamic-loading.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule
  ],
  declarations: [...fromComponents.components,
    LoadingComponent,
    ButtonExportExcelComponent,
    DevTestComponent,
    DynamicChartComponent,
    ButtonExportGoogleSlidesComponent,
    SharedDialogGoogleSlidesComponent,
    IsSuccessDialogGoogleSlidesComponent,
    DynamicButtonWrapperComponent,
    ExportButtonsComponent,
    ButtonExportsHtml2canvasComponent,
    DynamicTableComponent,
    DynamicLoadingComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonExportExcelComponent,
    DynamicChartComponent,
    ButtonExportGoogleSlidesComponent,
    DynamicButtonWrapperComponent,
    ExportButtonsComponent,
    DynamicTableComponent,
    ...fromComponents.components
  ]
})
export class SharedModule { }
