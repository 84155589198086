export const navbar_menu = {
    1: {
        items: [
            {
                icon: 'list_alt',
                selected: true,
                name: 'Reportes',
                path: 'private/cerebro/dashboard/reports'
            },
            {
                icon: 'supervisor_account',
                selected: false,
                name: 'Administración de Clientes',
                path: 'private/cerebro/dashboard/client-management'
            },
            {
                icon: null,
                selected: false,
                name: 'DV360',
                path: 'private/cerebro/dashboard/dv360-management',
                src: "https://firebasestorage.googleapis.com/v0/b/dv360-api-343616.appspot.com/o/platforms%2Fdv360%2Fdv360.png?alt=media&token=252a1ae5-fae7-4327-8f9d-403e4fc749c1"
            },
            {
                icon: null,
                selected: false,
                name: 'Facebook',
                path: 'private/cerebro/dashboard/facebook-management',
                src: "https://firebasestorage.googleapis.com/v0/b/dv360-api-343616.appspot.com/o/platforms%2Fdv360%2Ffacebook_logo.png?alt=media&token=46762841-14fb-40b7-9fd1-4ad8835206fb"
            },
            {
                icon: null,
                selected: false,
                name: 'Google Ads',
                path: 'private/cerebro/dashboard/google-ads-management',
                src: "https://firebasestorage.googleapis.com/v0/b/dv360-api-343616.appspot.com/o/platforms%2Fdv360%2Fgoogle_ads_logo.png?alt=media&token=7d958166-d7dc-4bae-a745-a0c17b6693a3"
            },
            {
                icon: null,
                selected: false,
                name: 'TikTok',
                path: 'private/cerebro/dashboard/tik-tok-management',
                src: "https://firebasestorage.googleapis.com/v0/b/dv360-api-343616.appspot.com/o/platforms%2Fdv360%2Ftiktok.avif?alt=media&token=b91f1a61-1284-442b-86b4-9f5d541a2cb7"
            },
        ]
    },
    2: {
        items: [
            {
                icon: 'list_alt',
                selected: true,
                name: 'Reportes',
                path: 'private/cerebro/dashboard/reports'
            },
            {
                icon: 'supervisor_account',
                selected: false,
                name: 'Administración de Clientes',
                path: 'private/cerebro/dashboard/client-management'
            },
            {
                icon: null,
                selected: false,
                name: 'DV360',
                path: 'private/cerebro/dashboard/dv360-management',
                src: "https://firebasestorage.googleapis.com/v0/b/dv360-api-343616.appspot.com/o/platforms%2Fdv360%2Fdv360.png?alt=media&token=252a1ae5-fae7-4327-8f9d-403e4fc749c1"
            }
        ]
    },
    3: {
        items: [
            {
                icon: 'list_alt',
                selected: true,
                name: 'Reportes',
                path: 'private/client/dashboard/reports'
            }
        ]
    }
}