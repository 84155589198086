import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Data } from '@data/iterfaces/exports/googleSlides.interface';
import { SharedDataService } from '@data/services/shared-data/shared-data.service';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';

@Component({
  selector: 'app-is-success-dialog-google-slides',
  templateUrl: './is-success-dialog-google-slides.component.html',
  styleUrls: ['./is-success-dialog-google-slides.component.sass']
})
export class IsSuccessDialogGoogleSlidesComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public dialogRef: MatDialogRef<IsSuccessDialogGoogleSlidesComponent>,
    private snackbarService: SnackbarService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit(): void {
    this.snackbarService.openSuccess('Presentación generada exitosamente.');
    //this.initializeData();
    this.restoreDataReport();
  }

  restoreDataReport() {
    this.sharedDataService.setData('googleSlidesData', null);
    this.sharedDataService.setData('googleSlidesUpdateData', null);
  }

  initializeData() {
    console.log(JSON.stringify(this.data, null, 2));
  }

  downloadPresentation() {
    const presentationId = this.data.presentationId;
    const downloadUrl = `https://docs.google.com/presentation/d/${presentationId}/export/pptx`;

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'presentacion.pptx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
